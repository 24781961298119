<template>
    <div id="content" class="release" :class="[$mq]">
        <template v-if="!isPortrait">
            <div class="column left">
                <div v-for="rel in releases" :key="rel.id" class="item" :class="{ selected: rel.id == selected }" @click="selectRelease(rel.id)">
                    {{ rel.title }}
                    <span class="item-date">{{ formatDate(rel.public_date) }}</span>
                </div>
            </div>
            <div class="content" v-if="Object.keys(releases).length > 0 && release">
                <div class="title-release">
                    {{ release.title }}
                    <span>{{ formatDate(release.public_date) }}</span>
                </div>
                <div class="content-release" v-html="release.text"></div>
            </div>
        </template>
        <template v-else>
            <transition :name="transitionName">
                <div class="releases-container" v-if="Object.keys(releases).length > 0 && release && !selected" :key="0">
                    <div class="releases hide-scrollbar">
                        <div v-for="rel in releases" :key="rel.id" class="item" :class="{ selected: rel.id == selected }" @click="selectRelease(rel.id)">
                            {{ rel.title }}
                            <span class="item-date">{{ formatDate(rel.public_date) }}</span>
                        </div>
                    </div>
                </div>
                <div class="release-content" v-else :key="1">
                    <div class="title-release">
                        <div class="return-button" @click="returnToReleases()">{{ $t('release.return_back') }}</div>
                    </div>
                    <div class="release-title hide-scrollbar" v-html="release.title"></div>
                    <span>{{ formatDate(release.public_date) }}</span>
                    <div class="content-release" :class="[$mq]" v-html="release.text"></div>
                </div>
            </transition>
        </template>
    </div>
</template>

<script>
export default {
    name: 'Release',
    data() {
        return {
            selected: false,
            transitionName: 'next'
        }
    },
    computed: {
        release() {
            return this.$store.getters['login/getAllReleases'](this.selected)
        },
        releases() {
            var releases = this.$store.getters['login/getAllReleases']()
            return Object.values(releases).sort((a, b) => {
                return b.public_date - a.public_date
            })
        },
        isPortrait() {
            return this.$mq === 'portrait'
        }
    },
    methods: {
        returnToReleases() {
            this.transitionName = 'previous'
            this.selected = false
        },
        selectRelease(newVal) {
            this.transitionName = 'next'
            this.selected = newVal
        },
        formatDate(date) {
            return moment(parseInt(date) * 1000).format('DD/MM/YYYY')
        }
    },
    mounted() {
        if (!this.isPortrait) {
            this.selected = typeof this.$route.params.selected !== 'undefined' ? this.$route.params.selected : Object.keys(this.releases).length > 0 ? Object.values(this.releases)[0].id : false
        }
    },
    updated() {
        if (this.$store.getters.getIsApp) {
            document.getElementsByClassName('link-buttons').forEach(e => {
                var link = e.href.split('/')
                if (!e.href.includes('http')) {
                    console.log(link, window.location.pathname)
                    e.href = 'file:///' + window.location.pathname + '#/' + link[link.length - 1]
                }
            })
        }
    },
    watch: {
        $mq(newValue, oldValue) {
            if (newValue == 'desktop' || newValue == 'landscape') {
                this.selected = typeof this.$route.params.selected !== 'undefined' ? this.$route.params.selected : Object.keys(this.releases).length > 0 ? Object.values(this.releases)[0].id : false
            }
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
#content.release {
    .column {
        @include display-flex();
        @include flex-direction(column);

        .item {
            @include interaction();
            @include text-ellipsis();
            @include border-radius(6px);
            @include font-size(m);
            background-color: #fff;
            font-family: $conden;
            color: $neutro-s90;
            // line-height: 50px;
            min-height: 50px;
            height: auto;
            width: calc(100% - 12px);
            padding: 6px 15px;
            margin: 6px 6px 0;

            @include bgHover(#fff);

            &.selected {
                background-color: $main;
                color: #fff;
                font-family: $conden-bold;

                @include bgHover($main);
            }

            .item-date {
                @include font-size(xs);
                font-family: $conden;
                color: inherit;
                width: 100%;
                height: auto;
                display: block;
            }
        }
    }

    > .content {
        padding: 20px 40px;

        .title-release {
            @include font-size(l);
            font-family: $text-bold;
            color: $neutro-s90;
            padding-bottom: 6px;
            height: auto;
            text-align: center;

            span {
                @include font-size(s);
                font-family: $conden-bold;
                color: $inactive-s40;
                text-align: inherit;
                display: block;
                margin-top: 6px;
            }
        }
        .content-release {
            h1,
            h2 {
                @include font-size(l);
            }
            div,
            p,
            li {
                @include font-size(m);
                font-family: $text-medium;
                color: $neutro-s70;
            }

            img {
                width: 100%;
                height: auto;
                max-width: 400px;
            }
            div {
                padding-top: 6px;
                padding-bottom: 6px;
            }
            p {
                padding: 6px 0px;
            }

            ul,
            ol {
                padding-top: 6px;
                padding-bottom: 6px;
                margin-left: 30px;

                li {
                    list-style: disc;
                }
            }
            ul li::marker {
                font-size: 0.8em;
            }

            h1 {
                @include font-size(l);
                font-family: $text-bold;
                color: $neutro-s90;
            }
            h2 {
                @include font-size(ml);
                font-family: $text-bold;
                color: $neutro-s90;
            }

            a {
                font-size: inherit;
                color: $main-s30;
                text-decoration: underline;
                text-decoration-color: $main-s30;
            }
            .link-buttons,
            .app_buttons {
                @include align-items(center);
                @include background($image: img('right_arrow_main_s30.svg'), $position: right 12px center, $size: 15px);
                @include border-radius(4px);
                @include font-size(sm);
                background-color: #fff;
                padding-right: 35px;
                padding-left: 12px;
                box-shadow: 0px 0px 6px rgba(99, 108, 122, 0.3);
                height: 40px !important;
                width: fit-content;
                margin-left: 3px;
                display: inline-flex;
                margin-right: 6px;
                color: $main-s30;
                font-family: $conden;
                text-decoration: none;

                &:hover {
                    background-color: hover(#fff);
                }
            }
            .footer {
                @include font-size(s);
                color: $neutro-s50;
                font-family: $text-medium;
            }
        }
    }
}
</style>

<style lang="scss">
#content.release {
    &.portrait {
        width: 100%;
        height: calc(100% - 60px);
        overflow: hidden;

        .next-enter,
        .next-leave-to {
            transform: translateX(100%);
            opacity: 0;
        }

        .next-enter-active {
            transition: all 0.5s ease;
        }

        .next-leave-active {
            transform: translateX(-100%);
            transition: all 0.5s ease;
        }
        .previous-enter,
        .previous-leave-to {
            transform: translateX(-100%);
            opacity: 0;
        }

        .previous-enter-active {
            transition: all 0.5s ease;
        }

        .previous-leave-active {
            transform: translateX(100%);
            transition: all 0.5s ease;
        }

        .releases-container {
            width: 100%;
            height: 100%;
            overflow: hidden;
            padding: 10px;
            position: absolute;

            .releases {
                width: 100%;
                height: 100%;
                overflow-y: auto;

                .item {
                    @include display-flex();
                    @include flex-direction(column);
                    @include interaction();
                    @include border-radius(6px);
                    @include font-size(sm);
                    background-color: #fff;
                    font-family: $text;
                    color: $neutro-s90;
                    // line-height: 50px;
                    min-height: 50px;
                    height: auto;
                    width: 100%;
                    padding: 6px 15px;
                    margin-bottom: 6px;

                    @include bgHover($inactive-t90);
                    @include background($image: img('right_dark.svg'), $color: #fff, $size: 20px, $position: right 10px center);
                    padding-right: 25px;

                    span {
                        @include font-size(s);
                        font-family: $conden-bold;
                        color: $main-s40;
                        text-align: inherit;
                        display: block;
                        margin-top: 6px;
                    }
                }
            }
        }
        .release-content {
            @include display-flex();
            @include flex-direction(column);
            height: 100%;
            position: absolute;
            padding: 10px;

            .title-release {
                @include display-flex();
                @include flex-direction(column);
                height: 50px;
                overflow: hidden;
            }
            .title-content {
                @include display-flex();
                height: 45px;
                width: 100%;
            }
            .return-button {
                @include display-flex();
                @include align-items(center);
                @include background($image: img('left_dark.svg'), $color: #fff, $size: 20px);
                @include border-radius(5px 0 0 5px);
                width: fit-content;
                font-family: $text-sbold;
                height: 45px;
                min-width: 45px;
                min-height: 45px;
                padding-left: 40px;
                background-position: 5px;
                padding-right: 10px;
                cursor: pointer;
                border-radius: 4px 4px 4px 4px;

                &:hover {
                    @include bgHover(#fff);
                }
            }

            .release-title {
                @include font-size(m);
                @include display-flex();
                justify-content: center;
                font-family: $text-bold;
                color: $neutro-s90;
                text-align: center;
                height: fit-content;
                width: calc(100%);
                padding: 10px 0px;
            }

            span {
                @include align-self(center);
                @include font-size(xs);
                font-family: $conden-bold;
                color: $inactive-s40;
                text-align: inherit;
                display: block;
            }

            .content-release {
                &.portrait {
                    height: calc(100% - 75px);
                    overflow: scroll;
                    width: 100%;

                    div,
                    p,
                    li {
                        @include font-size(s);
                        font-family: $text-medium;
                        color: $neutro-s70;
                    }

                    img {
                        width: 100%;
                        height: auto;
                        max-width: 400px;
                    }
                    div {
                        @include font-size(s);
                        padding-top: 6px;
                        padding-bottom: 6px;
                    }
                    p {
                        padding: 6px 0px;
                    }

                    ul,
                    ol {
                        padding-top: 6px;
                        padding-bottom: 6px;
                        margin-left: 30px;

                        li {
                            list-style: disc;
                        }
                    }
                    ul li::marker {
                        font-size: 0.8em;
                    }

                    h1 {
                        @include font-size(m);
                        font-family: $text-bold;
                        color: $neutro-s90;
                    }
                    h2 {
                        @include font-size(sm);
                        font-family: $text-bold;
                        color: $neutro-s90;
                    }

                    a {
                        font-size: inherit;
                        color: $main-s30;
                        text-decoration: underline;
                        text-decoration-color: $main-s30;
                    }
                    .link-buttons,
                    .app_buttons {
                        @include align-items(center);
                        @include background($image: img('right_arrow_main_s30.svg'), $position: right 12px center, $size: 15px);
                        @include border-radius(4px);
                        @include font-size(s);
                        background-color: #fff;
                        padding-right: 35px;
                        padding-left: 12px;
                        box-shadow: 0px 0px 6px rgba(99, 108, 122, 0.3);
                        height: 50px !important;
                        width: fit-content;
                        margin-left: 3px;
                        display: inline-flex;
                        margin-right: 6px;
                        color: $main-s30;
                        font-family: $conden;
                        text-decoration: none;

                        &:hover {
                            background-color: hover(#fff);
                        }
                    }
                    .footer {
                        @include font-size(s);
                        color: $neutro-s50;
                        font-family: $text-medium;
                    }
                }
            }
        }
    }
}
</style>
